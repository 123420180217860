<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row align="center" justify="end">
                    <v-col cols="12" sm="6" md="4">
                        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable
                            placeholder="Search" id="csv-search">
                            <template slot="prepend">
                                <el-button @click="addFiles()" id="csv-add"><i class="el-icon-plus"></i></el-button>
                            </template>
                            <template slot="append">
                                <el-button @click="infoStartGuide">
                                    <v-icon>help</v-icon>
                                </el-button>
                            </template>
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table :headers="headers" :loading="loading" dense :items="mappedFiles" :search="params.search"
                    disable-pagination hide-default-footer height="65vh" fixed-header @click:row="editFileMapping"
                    style="cursor: pointer" id="csv-table">
                    <template v-slot:[`item.mappedHeaders`]="{ item }">
                        <v-chip>{{ item.stockFileMappingHeaders
                            .filter(x => x.field).length }}</v-chip>
                    </template>
                </v-data-table>
                <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true"
                    accept="text/csv,application/vnd.ms-excel,application/x-vnd.oasis.opendocument.spreadsheet,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :drop-directory="true" v-model="fileList" ref="uploader">
                </file-upload>
            </v-card-text>
        </v-card>

        <v-dialog v-model="uploadModal" width="1200px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="uploadedItem">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage Template: {{ uploadedItem.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon :loading="saving" :key="refreshKey"
                        :disabled="!uploadedItem.alias || !uploadedItem.label || !ssccLineAssigned()" color="secondary"
                        @click="saveFileMapping()"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="uploadModal = false, uploadedItem = null">X</v-btn>
                </v-toolbar>
                <v-card-text style="overflow-x: scroll; overflow-y: auto;max-height: 80vh">
                    <v-card flat>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-list>
                                        <v-text-field outlined dense clearable v-model="uploadedItem.alias"
                                            @change="refreshKey++" label="Alias*"></v-text-field>
                                        <v-text-field outlined dense clearable v-model="uploadedItem.label"
                                            @change="refreshKey++" label="File Label*" persistent-hint
                                            hint="The constant label that will be used in the file submitted by this source."></v-text-field>
                                        <v-select label="File Type" outlined dense
                                            :items="['Addendum', 'Load Out Instruction', 'MT File', 'PO File', 'Packing List']"
                                            v-model="uploadedItem.type"></v-select>
                                        <v-autocomplete prepend-inner-icon="business" label="Linked Organisation" outlined
                                            dense :loading="loadingOrganisations" :items="organisations.data"
                                            item-text="relatedOrganisation.name" item-value="relatedOrganisationId"
                                            v-model="uploadedItem.linkedOrganisationId"></v-autocomplete>
                                         <v-select v-if="sheets.length > 0" @change="updateData()" :items="sheets" item-text="name" item-value="index" outlined dense label="Selected Sheet" v-model="uploadedItem.tab"></v-select>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-icon color="secondary">tag</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ uploadedItem.name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Name
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-icon color="secondary">article</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ uploadedItem.fileType }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    File Type
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click="previewModal = true" :disabled="!ssccLineAssigned()">
                                            <v-list-item-action>
                                                <v-icon color="secondary">visibility</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Preview Layout
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="!ssccLineAssigned()">
                                                    Please select the matching SSCC/Pallet ID row
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-subheader style="font-size: 16px; font-weight: bold">Mapped Headers
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="setHeaderModal = true"
                                                    color="blue"><v-icon>autorenew</v-icon></v-btn>
                                            </template>
                                            <span>Change header row</span>
                                        </v-tooltip>
                                    </v-subheader>
                                    <v-list dense style="max-height: 60vh; overflow-y: auto">
                                        <v-list-item style="height: 30px"
                                            v-for="(header, index) in uploadedItem.mappedHeaders" :key="index">
                                            <v-list-item-action>
                                                <v-icon></v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ header.value }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                <v-autocomplete dense :items="mappingHeaders" item-text="name" clearable
                                                    @change="refreshKey++" item-value="field"
                                                    v-model="header.field"></v-autocomplete>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>

                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="previewModal" fullscreen>
            <v-card v-if="uploadedItem && previewModal">
                <v-card-title>
                    Preview Layout
                    <v-spacer></v-spacer>
                    <v-btn text @click="previewModal = false">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table dense height="85vh" fixed-header :headers="uploadedItem.constructedHeaders"
                        :items="getFilteredPallets" disable-pagination hide-default-footer></v-data-table>
                </v-card-text>
            </v-card>
            <v-card v-else-if="item && previewModal">
                <v-card-title>
                    Preview Layout: item
                    <v-spacer></v-spacer>
                    <v-btn text @click="previewModal = false">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table dense height="85vh" fixed-header :headers="item.headerData" :items="item.fileData"
                        disable-pagination hide-default-footer></v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="setHeaderModal" fullscreen>
            <v-card v-if="uploadedItem">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>Select Header Row</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="setHeaderModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-data-table @click:row="selectHeaderRow" style="cursor: pointer" fixed-header disable-pagination
                        hide-default-footer height="85vh" :headers="constructFileHeaders"
                        :items="constructFileData"></v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmHeaderRow" width="600px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="headerRowItem">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Confirm Header Row
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="confirmHeaderRow = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <p>Are you sure you want to set the header row to these values?</p>
                    <span>{{ headerRowItem.headers.join(', ') }}</span>
                    <v-row justify="center" class="mt-3">
                        <v-btn color="red" class="mx-1" @click="confirmHeaderRow = false">No</v-btn>
                        <v-btn color="success" class="mx-1" @click="setNewHeaders">Yes</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editModal" persistent width="1200px">
            <v-card v-if="item">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Mapped File: {{ item.alias }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="red" :loading="deleting" @click="deleteMapping(item)"><v-icon>delete</v-icon></v-btn>
                    <v-btn icon :key="refreshKey" color="secondary" @click="updateFileMapping" :loading="saving"
                        :disabled="!item.alias || !item.label || !ssccLineAssigned()"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="editModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list>
                                <v-text-field outlined dense clearable v-model="item.alias" @change="refreshKey++"
                                    label="Alias*"></v-text-field>
                                <v-text-field outlined dense clearable v-model="item.label" @change="refreshKey++"
                                    label="File Label*"
                                    hint="The constant label that will be used in the file submitted by this source."></v-text-field>
                                <v-select label="File Type" outlined dense
                                    :items="['Addendum', 'Load Out Instruction', 'MT File', 'PO File', 'Packing List']"
                                    v-model="item.type"></v-select>
                                <v-autocomplete prepend-inner-icon="business" label="Linked Organisation" outlined dense
                                    :loading="loadingOrganisations" :items="organisations.data"
                                    item-text="relatedOrganisation.name" item-value="relatedOrganisationId"
                                    v-model="item.linkedOrganisationId"></v-autocomplete>
                                    <v-select v-if="item.sheets && item.sheets.length > 0" disabled :items="item.sheets" item-text="name" item-value="index" outlined dense label="Selected Sheet" v-model="item.tab"></v-select>

                                <v-list-item>
                                    <v-list-item-action>
                                        <v-icon color="secondary">tag</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Name
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-icon color="secondary">article</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.fileType }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            File Type
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="previewModal = true" :key="refreshKey"
                                    v-if="item.fileData && item.headerData">
                                    <v-list-item-action>
                                        <v-icon color="secondary">visibility</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Preview Layout
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.url" @click="downloadFile">
                                    <v-list-item-action>
                                        <v-icon color="secondary">download</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Download Original File
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-progress-circular size="24" v-if="downloadingFile" color="primary"
                                            indeterminate></v-progress-circular>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-subheader style="font-size: 16px; font-weight: bold">Mapped Headers
                            </v-subheader>
                            <v-list dense style="max-height: 60vh; overflow-y: auto" class="mx-0 px-0">
                                <v-list-item style="height: 30px" v-for="(header, index) in item.stockFileMappingHeaders"
                                    :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ header.value }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <!-- <v-list-item-title>
                                            <span>{{ findField(header.field) }}</span>
                                        </v-list-item-title> -->
                                        <v-autocomplete dense :items="mappingHeaders" item-text="name" item-value="field"
                                            clearable v-model="header.field"></v-autocomplete>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="infoStart" max-width="31vw">
            <v-card>
                <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
                        color="primary" size="30">close</v-icon></v-btn>
                <v-card-title class="d-flex justify-center">
                    FRIKO Welcome to CSV Mapping!
                </v-card-title>
                <v-card-text>
                    <span>
                        <p>FRIKO</p>
                        <p>FRIKO
                            <br>• Map out routes for transporters
                            <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                            <br>• Calculate accurate waiting times at loading points
                            <br>• Calculate distances for billing purposes
                            <br>• Pin-point locations for the purpose of reporting and analysis
                            <br>• Map out farms that aren't found on Google Maps
                        </p>
                        If you would like learn how to load POI's into the system, click the button below to start the
                        tutorial.
                    </span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import FileUpload from "vue-upload-component";
import * as XLSX from "xlsx"
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from './Steps/CSV'
export default {
    components: {
        FileUpload
    },
    data: () => ({
        steps: null,
        driver: null,
        infoStart: false,
        confirmHeaderRow: false,
        deleting: false,
        documentKey: 1000,
        downloadingFile: false,
        editModal: false,
        fileList: [],
        headers: [
            // {
            //     text: "Action",
            //     align: "center",
            //     sortable: false,
            //     value: "action",
            //     width: "115px",
            // },
            {
                text: "Alias",
                value: "alias",
                align: "center",
            },
            {
                text: "Type",
                value: "type",
                align: "center",
            },
            {
                text: "Name",
                value: "name",
                align: "left",
            },
            {
                text: "Label",
                value: "label",
                align: "center",
            },
            {
                text: "Linked Organisation",
                value: "linkedOrganisation.name",
                align: "center",
            },
            {
                text: "Mime Type",
                value: "fileType",
                align: "center"
            },
            {
                text: "Mapped Headers",
                value: "mappedHeaders",
                align: "center"
            }

        ],
        headerRowItem: null,
        item: {},
        lastIndex: 0,
        loading: false,
        loadingOrganisations: false,
        mappingTab: 0,
        mappedFiles: [],
        mappingHeaders: [],
        organisations: {
            total: 0,
            data: []
        },
        params: {
            limit: 15,
            offset: 0,
            search: null
        },
        previewModal: false,
        refreshKey: 1000,
        saving: false,
        setHeaderModal: false,
        sheets: [],
        supportingDocuments: [],
        uploadedItem: null,
        uploadModal: false
    }),
    watch: {
        fileList: {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.submitFiles();
                }
            },
        },
    },
    async created() {
        this.getFileMappings()
        this.mappingHeaders = await this.$API.getMappingHeaders()
        this.getOrganisations()
        await this.getSteps();
    },
    async mounted() {
        this.driver = new Driver({
            animate: false
        })
    },
    computed: {
        constructFileHeaders() {
            let result = this.uploadedItem.data
            let longestLine = Math.max(...result.map(x => x.length));
            let possibleHeaders = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
            let lastHeader = null
            let headers = []
            for (let i = 0; i < longestLine; i++) {
                if (i < possibleHeaders.length) {
                    lastHeader = possibleHeaders[i]
                    headers.push({
                        text: lastHeader,
                        value: lastHeader,
                        align: 'center'
                    })
                } else {
                    let divisor = Math.floor(i / possibleHeaders.length)
                    lastHeader = possibleHeaders[i - (possibleHeaders.length * divisor)]
                    if (lastHeader.length > 1) {
                        lastHeader = lastHeader.substring(0, lastHeader.length - 1) + possibleHeaders[i - (possibleHeaders.length * divisor)]
                    } else {
                        lastHeader = lastHeader + possibleHeaders[i - (possibleHeaders.length * divisor)]
                    }
                    headers.push({
                        text: lastHeader,
                        value: lastHeader,
                        align: 'center'
                    })
                }
            }
            headers.unshift({
                text: 'Line',
                value: 'line',
                align: 'center'
            })
            return headers
        },
        constructFileData() {
            let headers = this.constructFileHeaders
            let result = []
            for (let i = 0; i < this.uploadedItem.data.length; i++) {
                let data = this.uploadedItem.data[i]
                let obj = {}
                for (let j = 0; j < headers.length; j++) {
                    if (j == 0) {
                        obj[headers[j].value] = i + 1
                    } else {
                        obj[headers[j].value] = data[j - 1]
                    }
                }
                result.push(obj)
            }
            return result
        },
        getFilteredPallets() {
            let result = []
            if (this.uploadedItem && this.uploadedItem.constructedData) {
                result = this.uploadedItem.constructedData
                let findSSCCColumn = this.uploadedItem.mappedHeaders.find(x => x.field == 'sscc')
                let findData = this.uploadedItem.constructedData.find(x => x[findSSCCColumn.value])
                if (findData) {
                    let length = findData[findSSCCColumn.value].length
                    result = this.uploadedItem.constructedData.filter(x => x[findSSCCColumn.value] && x[findSSCCColumn.value].length == length)
                    result = result.map(x => ({
                        ...x,
                        [findSSCCColumn.value]: this.formatSSCC(x[findSSCCColumn.value])
                    }))
                    result = result.filter(x => x[findSSCCColumn.value])
                }
            } else if (this.item && this.item.stockFileMappingHeaders) {
                let find = this.item.stockFileMappingHeaders.find(x => x.field == 'sscc')
                if (find) {
                    result = true
                }
            }
            return result
        },

    },
    methods: {
        async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'Stock/CSVMapping/Steps',
        });
        this.steps = steps.steps
        },
        addFiles() {
            this.$refs.uploader.$el.children[0].click();
        },
        autoMapHeaders() {
            let headers = this.uploadedItem.mappedHeaders
            for (let i = 0; i < headers.length; i++) {
                let value = headers[i].value
                if (value) {
                    let findMatch = this.mappingHeaders.find(x => x.field == value || (x.labels && x.labels.map(y => y.toLowerCase()).includes((value.toString()).toLowerCase())))
                    if (findMatch) {
                        headers[i].field = findMatch.field
                    }
                }

            }
        },
        csvJSON(csv, file) {
            var lines = csv.split("\n");
            var result = lines.map(x => x.split(',').filter(y => y != '\r'));
            result = result.filter(x => x.length > 0);
            let longestLine = Math.max(...result.map(x => x.length));
            let index = result.findIndex(x => x.length == longestLine);
            let extension = file.name.split('.').at(-1);
            this.uploadedItem = {
                name: file.name,
                type: "MT File",
                file: file,
                fileExtension: extension,
                fileType: file.type,
                headerLength: longestLine,
                headerIndex: index,
                headers: result[index],
                constructedHeaders: result[index].map((x, i) => ({ text: x, value: x, align: 'center' })),
                constructedData: [],
                data: result,
                table: result.filter((x, i) => i != index),
                placeholder: this.getPlaceholderName(file.name, extension),
                mappedHeaders: result[index].map(x => ({ value: x, field: null }))
            }
            this.autoMapHeaders()
            this.uploadedItem.constructedHeaders.unshift({
                text: 'Line',
                value: 'line',
                align: 'center'
            })
            for (let i = 0; i < this.uploadedItem.table.length; i++) {
                this.uploadedItem.constructedData[i] = {
                    line: i + 1
                }
                for (let j = 0; j < this.uploadedItem.headers.length; j++) {
                    this.uploadedItem.constructedData[i][this.uploadedItem.headers[j]] = this.uploadedItem.table[i][j]
                }
            }
            this.uploadedItem.allConstructedData = this.uploadedItem.constructedData
            // console.log(this.uploadedItem)
            this.uploadModal = true
        },
        async downloadFile() {
            this.downloadingFile = true
            // let result = await axios.get(this.item.url)
            // console.log(result.headers)
            var hiddenElement = document.createElement("a");
            hiddenElement.href = this.item.url;
            hiddenElement.target = "_blank";
            hiddenElement.download = this.item.name;
            hiddenElement.click();
            this.downloadingFile = false
        },
        excelJson(excel, file, sheets, allData) {
            let result = excel
            this.sheets = sheets
            // result = result.filter(x => x.length > 0);
            let longestLine = Math.max(...result.map(x => x.length));
            let index = result.findIndex(x => x.length == longestLine);
            let extension = file.name.split('.').at(-1);
            this.uploadedItem = {
                name: file.name,
                type: "MT File",
                tab: 0,
                file: file,
                sheets: sheets,
                fileExtension: extension,
                fileType: file.type,
                headerLength: longestLine,
                headerIndex: index,
                headers: result[index],
                constructedHeaders: result[index].filter(Boolean).map((x, i) => ({ text: x, value: x, align: 'center' })),
                constructedData: [],
                data: result,
                table: result.filter((x, i) => i > index),
                placeholder: this.getPlaceholderName(file.name, extension),
                mappedHeaders: result[index].filter(Boolean).map(x => ({ value: x, field: null })),
                allData
            }
            this.autoMapHeaders()
            this.uploadedItem.constructedHeaders.unshift({
                text: 'Line',
                value: 'line',
                align: 'center'
            })
            for (let i = 0; i < this.uploadedItem.table.length; i++) {
                this.uploadedItem.constructedData[i] = {
                    line: i + 1
                }
                for (let j = 0; j < this.uploadedItem.headers.length; j++) {
                    if (this.uploadedItem.table[i][j]) {
                        this.uploadedItem.constructedData[i][this.uploadedItem.headers[j]] = this.uploadedItem.table[i][j]

                    } else {
                        this.uploadedItem.constructedData[i][this.uploadedItem.headers[j]] = null

                    }
                }
            }
            this.uploadedItem.allConstructedData = this.uploadedItem.constructedData

            this.uploadModal = true
        },
        findField(field) {
            let find = this.mappingHeaders.find(x => x.field == field)
            return find ? find.name : null
        },
        async deleteMapping(item) {
            this.$confirm(
                "Are you sure you want to delete this mapped file?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    this.deleting = true
                    item.isDeleted = true;
                    item.isActive = false;
                    await this.$API.updateFileMapping(item);
                    let index = this.mappedFiles.findIndex(
                        (x) => x.id == item.id
                    );
                    this.mappedFiles.splice(index, 1);
                    this.editModal = false
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.deleting = false
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled",
                    });
                    this.deleting = false
                });
        },
        async editFileMapping(item) {
            this.item = item
            if (this.item.constructedFileDataURL) {
                let fileData = await axios.get(this.item.constructedFileDataURL)
                this.item.fileData = fileData.data
            }
            if (this.item.constructedFileHeaderURL) {
                let headerData = await axios.get(this.item.constructedFileHeaderURL)
                this.item.headerData = headerData.data
            }
            this.editModal = true
        },
        formatSSCC(sscc) {
            let result = ''
            if (sscc) {
                for (let i = 0; i < sscc.length; i++) {
                    if (!isNaN(sscc[i])) {
                        result += sscc[i]
                    }
                }
            }
            return result
        },
        async getFileMappings() {
            this.loading = true
            this.mappedFiles = await this.$API.getFileMappings()
            this.loading = false
        },
        getPlaceholderName(name, extension) {
            name = name.replace('.' + extension, '')
            let result = ''
            let delimiters = ['-', '_', '.']
            for (let i = 0; i < name.length; i++) {
                if (!isNaN(name[i]) && name[i] != ' ') {
                    result += '#'
                } else if (name[i] == ' ') {
                    result += ' '
                } else if (isNaN(name[i]) && !delimiters.includes(name[i])) {
                    result += "A"
                } else if (delimiters.includes(name[i])) {
                    result += name[i]
                }
            }
            return result
        },
        getIcon(name) {
            let type = name.split(".");
            let result = null;
            if (type.length > 0) {
                result = type.at(-1).toLowerCase();
            }
            if (result && result.toLowerCase() == "xlsx") {
                result = "xls";
            }
            return `/icons/${result}.svg`;
        },
        async getOrganisations() {
            this.loadingOrganisations = true
            this.organisations = await this.$API.getRelationBasic({
                // params: this.params,
            });
            this.loadingOrganisations = false
        },

        async getSupportingDocuments() {
            this.supportingDocuments = await this.$API.getCountrySupportingDocuments(
                this.country.id
            );
        },
        async saveFileMapping() {
            this.saving = true
            let obj = {
                alias: this.uploadedItem.alias,
                name: this.uploadedItem.name,
                type: this.uploadedItem.type,
                label: this.uploadedItem.label,
                placeholder: this.getPlaceholderName(this.uploadedItem.name, this.uploadedItem.fileExtension),
                fileType: this.uploadedItem.fileType,
                fileExtension: this.uploadedItem.fileExtension,
                headerRowIndex: this.uploadedItem.headerIndex,
                stockFileMappingHeaders: this.uploadedItem.mappedHeaders,
                constructedFileData: this.getFilteredPallets,
                constructedHeaders: this.uploadedItem.constructedHeaders,
                tab: this.uploadedItem.tab,
                sheets: this.sheets
            }
            if (this.uploadedItem.file) {
                let url = await URL.createObjectURL(this.uploadedItem.file);
                await this.toDataUrl(url, async (data) => {
                    obj.file = {
                        name: this.uploadedItem.file.name,
                        data: data,
                        type: this.uploadedItem.file.type
                    };
                    let result = await this.$API.createFileMapping(obj);
                    this.getFileMappings()

                });

            }
            setTimeout(() => {
                this.uploadModal = false
                this.saving = false
            }, 1500)

        },
        async updateFileMapping() {
            this.saving = true
            await this.$API.updateFileMapping(this.item)
            this.editModal = false
            this.getFileMappings()
            this.saving = false
        },
        async submitFiles() {
            let pending = this.fileList.filter((x) => !x.loading);
            for (let i = 0; i < pending.length; i++) {
                var vm = this;
                let file = pending[i].file;
                if (window.FileReader) {
                    var reader = new FileReader();
                    if (pending[i].file.type == 'text/csv') {
                        reader.readAsText(pending[i].file);
                        // Handle errors load
                        reader.onload = function (event) {
                            var csv = event.target.result;
                            vm.parse_csv = vm.csvJSON(csv, file);
                        };
                    } else {
                        reader.onload = (e) => {
                            /* Parse data */
                            const bstr = e.target.result;
                            const wb = XLSX.read(bstr, { type: 'binary' });
                            /* Get first worksheet */
                            let sheets = wb.SheetNames.map((x, index)=>({index: index, name: x}))
                            // this.uploadedItem.tab = 0
                            const wsname = wb.SheetNames[0];
                            const ws = wb.Sheets[wsname];
                            /* Convert array of arrays */
                            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                            let allData = []
                            for(let sheet in wb.SheetNames){
                                let sheetData = XLSX.utils.sheet_to_json( wb.Sheets[wb.SheetNames[sheet]], { header: 1 });
                                allData.push(sheetData)
                            }
                            //   console.log(data)
                            vm.excelJson(data, file, sheets, allData)
                        }

                        reader.readAsBinaryString(file);
                    }

                    reader.onerror = function (evt) {
                        if (evt.target.error.name == "NotReadableError") {
                            alert("Cannot read file !");
                        }
                    };
                } else {
                    alert("FileReader are not supported in this browser.");
                }
            }
        },
        selectHeaderRow(item, index) {
            this.headerRowItem = {
                index: index.index,
                headers: Object.values(item).filter((x, i) => i > 0)
            }
            this.confirmHeaderRow = true
        },
        setNewHeaders() {
            this.uploadedItem = {
                ...this.uploadedItem,
                headerIndex: this.headerRowItem.index,
                headers: this.headerRowItem.headers,
                headerLength: this.headerRowItem.headers.length,
                constructedHeaders: this.headerRowItem.headers.map((x, i) => ({ text: x, value: x, align: 'center' })),
                constructedData: [],
                table: this.uploadedItem.data.filter((x, i) => i > this.headerRowItem.index),
                mappedHeaders: this.headerRowItem.headers.map(x => ({ value: x, field: null }))
            }
            this.autoMapHeaders()
            this.uploadedItem.constructedHeaders.unshift({
                text: 'Line',
                value: 'line',
                align: 'center'
            })
            for (let i = 0; i < this.uploadedItem.table.length; i++) {
                this.uploadedItem.constructedData[i] = {
                    line: i + 1
                }
                for (let j = 0; j < this.uploadedItem.headers.length; j++) {
                    this.uploadedItem.constructedData[i][this.uploadedItem.headers[j]] = this.uploadedItem.table[i][j]
                }
            }
            this.confirmHeaderRow = false
            this.headerRowItem = null
            this.setHeaderModal = false

        },
        ssccLineAssigned() {
            let result = false
            if (this.uploadedItem && this.uploadedItem.mappedHeaders) {
                let find = this.uploadedItem.mappedHeaders.find(x => x.field == 'sscc')
                if (find) {
                    result = true
                }
            } else if (this.item && this.item.stockFileMappingHeaders) {
                let find = this.item.stockFileMappingHeaders.find(x => x.field == 'sscc')
                if (find) {
                    result = true
                }
            }
            return result
        },
        async submitUpload(obj) {
            let Api = axios.create({
                // baseURL: "http://localhost:3000",
                baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
                headers: {
                    Authorization: `${this.$store.state.token}`,
                    apikey: `${this.$store.state.orgToken}`,
                },
            });
            let result = await Api.post("/stock/upload", obj, {
                onUploadProgress: (progressEvent) => {
                    let find = this.fileList.findIndex(x => x.fileIndex == obj.fileIndex)
                    if (find > -1) {
                        this.fileList[find].progress = Math.ceil(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    }
                }
            });
            result = result.data;
            let find = this.fileList.findIndex(x => x.fileIndex == obj.fileIndex)
            this.fileList.splice(find, 1)
            this.$message.success('Successfully uploaded ' + obj.name + '!')
            this.documentKey++;
        },
        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
        infoStartGuide() {
            if (this.infoStart) {
                this.infoStart = false;
            } else {
                this.infoStart = true;
            }
        },
        updateData(){
            let result = this.uploadedItem.allData[this.uploadedItem.tab]
            let longestLine = Math.max(...result.map(x => x.length));
            let index = result.findIndex(x => x.length == longestLine);
            this.uploadedItem = {
                ...this.uploadedItem,
                headerLength: longestLine,
                headerIndex: index,
                headers: result[index],
                constructedHeaders: result[index].filter(Boolean).map((x, i) => ({ text: x, value: x, align: 'center' })),
                constructedData: [],
                data: result,
                table: result.filter((x, i) => i > index),
                mappedHeaders: result[index].filter(Boolean).map(x => ({ value: x, field: null }))
            };
            this.autoMapHeaders()
            this.uploadedItem.constructedHeaders.unshift({
                text: 'Line',
                value: 'line',
                align: 'center'
            })
            for (let i = 0; i < this.uploadedItem.table.length; i++) {
                this.uploadedItem.constructedData[i] = {
                    line: i + 1
                }
                for (let j = 0; j < this.uploadedItem.headers.length; j++) {
                    if (this.uploadedItem.table[i][j]) {
                        this.uploadedItem.constructedData[i][this.uploadedItem.headers[j]] = this.uploadedItem.table[i][j]
                    } else {
                        this.uploadedItem.constructedData[i][this.uploadedItem.headers[j]] = null
                    }
                }
            }
            this.uploadedItem.allConstructedData = this.uploadedItem.constructedData
            this.uploadModal = true
        },
        startGuide(e) {
            this.infoStart = false
            e.stopPropagation();
            this.driver.defineSteps(this.steps)
            this.driver.start()
        },
    }

}
</script>